import {forwardRef} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import {paths} from "src/routes/paths";
import {RouterLink} from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({disabledLink = false, sx, square = false, ...other}, ref) => {
  const squareLogo = (
    <Box
      component="img"
      src="/logo/fofoo_square.svg"
      sx={{width: 40, height: 40, cursor: 'pointer', ...sx}}
    />
  );

  const logo = (
    <Box
      component="img"
      src="/logo/fofoo.svg"
      sx={{ height: 40, cursor: 'pointer', ...sx}}
    />
  );

  if (disabledLink) {
    return square ? squareLogo : logo;
  }

  return (
    <Link component={RouterLink} href={paths.homepage} sx={{display: 'contents'}}>
      {square ? squareLogo : logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  square: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
