'use client';

import {useEffect} from 'react'
import PropTypes from 'prop-types';

import Page500 from "src/sections/error/500-view";

function Error({error, reset}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return (
    <>
      <title>Coś poszło nie tak (500 internal server error)</title>
      <Page500 error={error}/>
    </>
  )
}

Error.propTypes = {
  error: PropTypes.object,
  reset: PropTypes.func
}

export default Error;
