'use client';

import {m} from 'framer-motion';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import CompactLayout from 'src/layouts/compact';
import {SeverErrorIllustration} from 'src/assets/illustrations';

import {varBounce, MotionContainer} from 'src/components/animate';

// ----------------------------------------------------------------------

function Page500({error}) {
  return (
    <CompactLayout>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{mb: 2}}>
            500 Internal Server Error
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{color: 'text.secondary'}}>
            Przepraszamy, wystąpił błąd aplikacji. Spróbuj ponownie później.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{color: 'text.secondary'}}>
            {`Identyfikator błędu: ${error.digest}`}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <SeverErrorIllustration sx={{height: 260, my: {xs: 5, sm: 10}}}/>
        </m.div>
      </MotionContainer>
    </CompactLayout>
  );
}

Page500.propTypes = {
  error: PropTypes.object
}

export default Page500;
